:root {
  --neutral-white: #ffffff;
  --neutral-black: #000000;
  --neutral: var(--neutral-white);
  --invert-neutral: var(--neutral-black);
  --gray-50: #f8f8f8;
  --gray-100: #f3f3f3;
  --gray-200: #ededed;
  --gray-300: #e2e2e2;
  --gray-400: #c7c7c7;
  --gray-500: #999999;
  --gray-600: #7c7c7c;
  --gray-700: #525252;
  --gray-800: #383838;
  --gray-900: #171717;
  --blue-50: #f7fbfd;
  --blue-100: #edf6fd;
  --blue-200: #e3f1fd;
  --blue-300: #c9e7fc;
  --blue-400: #70b6f0;
  --blue-500: #0289f7;
  --blue-600: #007be0;
  --blue-700: #0070cc;
  --blue-800: #005ca3;
  --blue-900: #004880;
  --green-50: #f3fcf5;
  --green-100: #e4f5e9;
  --green-200: #daf0e1;
  --green-300: #cae5d4;
  --green-400: #b6dec5;
  --green-500: #59ba8b;
  --green-600: #30a66d;
  --green-700: #278f5e;
  --green-800: #16794c;
  --green-900: #173b2c;
  --red-50: #fff7f7;
  --red-100: #fff0f0;
  --red-200: #fcd7d7;
  --red-300: #f9c6c6;
  --red-400: #eb9091;
  --red-500: #e03636;
  --red-600: #cc2929;
  --red-700: #b52a2a;
  --red-800: #941f1f;
  --red-900: #6b1515;
  --orange-50: #fff9f5;
  --orange-100: #fff1e7;
  --orange-200: #fce6d5;
  --orange-300: #f7d6bd;
  --orange-400: #f0b58b;
  --orange-500: #e86c13;
  --orange-600: #d45a08;
  --orange-700: #bd3e0c;
  --orange-800: #9e3513;
  --orange-900: #6b2711;
  --amber-50: #fdfaed;
  --amber-100: #fcf3cf;
  --amber-200: #f7e28d;
  --amber-300: #f5d261;
  --amber-400: #f2be3a;
  --amber-500: #e79913;
  --amber-600: #db7706;
  --amber-700: #b35309;
  --amber-800: #91400d;
  --amber-900: #763813;
  --yellow-50: #fffcef;
  --yellow-100: #fff7d3;
  --yellow-200: #f7e9a8;
  --yellow-300: #f5e171;
  --yellow-400: #f2d14b;
  --yellow-500: #edba13;
  --yellow-600: #d1930d;
  --yellow-700: #ab6e05;
  --yellow-800: #8c5600;
  --yellow-900: #733f12;
  --cyan-50: #f5fbfc;
  --cyan-100: #e0f8ff;
  --cyan-200: #b3ecfc;
  --cyan-300: #94e6ff;
  --cyan-400: #6bd3f2;
  --cyan-500: #34bae3;
  --cyan-600: #32a4c7;
  --cyan-700: #267a94;
  --cyan-800: #125c73;
  --cyan-900: #164759;
  --teal-50: #f0fdfa;
  --teal-100: #e6f7f4;
  --teal-200: #bae8e1;
  --teal-300: #97ded4;
  --teal-400: #73d1c4;
  --teal-500: #36baad;
  --teal-600: #0b9e92;
  --teal-700: #0f736b;
  --teal-800: #115c57;
  --teal-900: #114541;
  --violet-50: #fbfaff;
  --violet-100: #f5f2ff;
  --violet-200: #e5e1fa;
  --violet-300: #dad2f7;
  --violet-400: #bdb1f0;
  --violet-500: #6846e3;
  --violet-600: #5f46c7;
  --violet-700: #4f3da1;
  --violet-800: #392980;
  --violet-900: #251959;
  --pink-50: #fff7fc;
  --pink-100: #feeef8;
  --pink-200: #f8e2f0;
  --pink-300: #f2d4e6;
  --pink-400: #e9c4da;
  --pink-500: #e34aa6;
  --pink-600: #cf3a96;
  --pink-700: #9c2671;
  --pink-800: #801458;
  --pink-900: #570f3e;
  --purple-50: #fdfaff;
  --purple-100: #f9f0ff;
  --purple-200: #f1e5fa;
  --purple-300: #e9d6f5;
  --purple-400: #d6c1e6;
  --purple-500: #9c45e3;
  --purple-600: #8642c2;
  --purple-700: #6e399d;
  --purple-800: #5c2f83;
  --purple-900: #401863;
  --white-overlay-50: rgba(255,255,255,0.090);
  --white-overlay-100: rgba(255,255,255,0.180);
  --white-overlay-200: rgba(255,255,255,0.270);
  --white-overlay-300: rgba(255,255,255,0.360);
  --white-overlay-400: rgba(255,255,255,0.450);
  --white-overlay-500: rgba(255,255,255,0.540);
  --white-overlay-600: rgba(255,255,255,0.630);
  --white-overlay-700: rgba(255,255,255,0.720);
  --white-overlay-800: rgba(255,255,255,0.810);
  --white-overlay-900: rgba(255,255,255,0.900);
  --black-overlay-50: rgba(0,0,0,0.090);
  --black-overlay-100: rgba(0,0,0,0.180);
  --black-overlay-200: rgba(0,0,0,0.270);
  --black-overlay-300: rgba(0,0,0,0.360);
  --black-overlay-400: rgba(0,0,0,0.450);
  --black-overlay-500: rgba(0,0,0,0.540);
  --black-overlay-600: rgba(0,0,0,0.630);
  --black-overlay-700: rgba(0,0,0,0.720);
  --black-overlay-800: rgba(0,0,0,0.810);
  --black-overlay-900: rgba(0,0,0,0.900);
  --linear-black: linear-gradient(to bottom, rgba(46,46,46,0.180) 0%,rgba(36,36,36,0.140) 100%);
  --linear-blue: linear-gradient(to bottom, rgba(17,142,245,0.067) 0%,rgba(7,127,247,0.029) 100%);
  --angular-white: conic-gradient(rgba(255,255,255,1) 72.38%,rgba(255,255,255,1) 99.87%);
  --angular-black: conic-gradient(rgba(56,56,56,0.220) 72.38%,rgba(56,56,56,0.220) 99.87%);
  --angular-green: conic-gradient(rgba(23,117,75,0.092) 72.38%,rgba(23,117,75,0.092) 99.87%);
  --angular-red: conic-gradient(rgba(205,41,41,0.804) 72.38%,rgba(205,41,41,0.804) 99.87%);
  --angular-blue: conic-gradient(rgba(0,110,219,0.000) 72.38%,rgba(0,110,219,0.000) 99.87%);
}

:root {
  --input-padding: 6px 8px;
  --dropdown-padding: 4px 8px;
  --grid-padding: 10px 8px;
  --disabled-input-padding: 3px 8px;
  --number-card-padding: 8px 8px 8px 12px;
}

:root {
  --font-stack: "Inter V", "Inter", "-apple-system", "BlinkMacSystemFont","Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell","Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --text-tiny: 11px;
  --text-2xs: 12px;
  --text-xs: 12px;
  --text-sm: 13px;
  --text-md: 13px;
  --text-base: 14px;
  --text-lg: 16px;
  --text-xl: 18px;
  --text-2xl: 20px;
  --text-3xl: 24px;
  --text-4xl: 26px;
  --text-5xl: 28px;
  --text-6xl: 32px;
  --text-7xl: 40px;
  --text-8xl: 44px;
  --text-9xl: 48px;
  --text-10xl: 52px;
  --text-11xl: 56px;
  --text-12xl: 64px;
  --weight-regular: 420;
  --weight-medium: 500;
  --weight-semibold: 600;
  --weight-bold: 700;
  --weight-black: 800;
  --text-line-height-3xl: 115%;
  --text-line-height-4xl: 160%;
  --text-line-height-7xl: 140%;
  --text-line-height-12xl: 130%;
  --text-line-height-14xl: 120%;
  --para-line-height-2-xs: 160%;
  --para-line-height-sm: 150%;
  --para-line-height-2xl: 148%;
  --para-line-height-3xl: 140%;
  --heading-color: var(--gray-900);
  --text-neutral: var(--gray-900);
  --text-color: var(--gray-800);
  --text-muted: var(--gray-700);
  --text-light: var(--gray-600);
  --text-dark: var(--fg-color);
}

:root {
  --shadow-xs: rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
  --shadow-sm: 0px 1px 2px rgba(0,0,0,0.100);
  --shadow-base: 0px 0px 1px rgba(0,0,0,0.450), 0px 1px 2px rgba(0,0,0,0.100);
  --shadow-md: 0px 0px 1px rgba(0,0,0,0.120), 0px 0.5px 2px rgba(0,0,0,0.150), 0px 2px 3px rgba(0,0,0,0.160);
  --shadow-lg: 0px 0px 1px rgba(0,0,0,0.350), 0px 6px 8px -4px rgba(0,0,0,0.100);
  --shadow-xl: 0px 0px 1px rgba(0,0,0,0.190), 0px 1px 2px rgba(0,0,0,0.070), 0px 6px 15px -5px rgba(0,0,0,0.110);
  --shadow-2xl: 0px 0px 1px rgba(0,0,0,0.200), 0px 1px 3px rgba(0,0,0,0.050), 0px 10px 24px -3px rgba(0,0,0,0.100);
  --focus-default: 0px 0px 0px 2px #c9c9c9;
  --focus-blue: 0px 0px 0px 2px #65b9fc;
  --focus-green: 0px 0px 0px 2px #5bb98c;
  --focus-yellow: 0px 0px 0px 2px #fff0ad;
  --focus-red: 0px 0px 0px 2px #eb9091;
  --custom-status: 0px 0px 0px 1.5px #ffffff;
  --custom-shadow-sm: 0px 1px 4px rgba(0,0,0,0.100);
  --drop-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0), 0px 2px 4px rgba(0, 0, 0, 0.05);
}

:root {
  --border-radius-tiny: 4px;
  --border-radius-sm: 8px;
  --border-radius: 8px;
  --border-radius-md: 10px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-2xl: 20px;
  --border-radius-full: 999px;
}

html, body {
  font-size: 16px;
}

:root,
[data-theme=light] {
  --brand-color: var(--primary);
  --padding-xs: 5px;
  --padding-sm: 6px;
  --padding-md: 15px;
  --padding-lg: 20px;
  --padding-xl: 30px;
  --padding-2xl: 40px;
  --margin-xs: 5px;
  --margin-sm: 10px;
  --margin-md: 15px;
  --margin-lg: 20px;
  --margin-xl: 30px;
  --margin-2xl: 40px;
  --modal-shadow: var(--shadow-md);
  --card-shadow: var(--shadow-sm);
  --btn-shadow: var(--shadow-xs);
  --navbar-height: 48px;
  --icon-fill: transparent;
  --icon-fill-bg: var(--fg-color);
  --icon-stroke: var(--gray-800);
  --bg-blue: var(--blue-100);
  --bg-light-blue: var(--blue-50);
  --bg-dark-blue: var(--blue-300);
  --bg-green: var(--green-100);
  --bg-yellow: var(--yellow-100);
  --bg-orange: var(--orange-100);
  --bg-red: var(--red-100);
  --bg-gray: var(--gray-100);
  --bg-grey: var(--gray-100);
  --bg-light-gray: var(--gray-100);
  --bg-dark-gray: var(--gray-400);
  --bg-darkgrey: var(--gray-400);
  --bg-purple: var(--purple-100);
  --bg-pink: var(--pink-50);
  --bg-cyan: var(--cyan-50);
  --text-on-blue: var(--blue-700);
  --text-on-light-blue: var(--blue-600);
  --text-on-dark-blue: var(--blue-800);
  --text-on-green: var(--green-800);
  --text-on-yellow: var(--yellow-700);
  --text-on-orange: var(--orange-700);
  --text-on-red: var(--red-700);
  --text-on-gray: var(--gray-700);
  --text-on-grey: var(--gray-700);
  --text-on-darkgrey: var(--gray-800);
  --text-on-dark-gray: var(--gray-800);
  --text-on-light-gray: var(--gray-800);
  --text-on-purple: var(--purple-700);
  --text-on-pink: var(--pink-700);
  --text-on-cyan: var(--cyan-700);
  --alert-text-danger: var(--red-600);
  --alert-text-warning: var(--yellow-700);
  --alert-text-info: var(--blue-700);
  --alert-text-success: var(--green-700);
  --alert-bg-danger: var(--red-50);
  --alert-bg-warning: var(--yellow-50);
  --alert-bg-info: var(--blue-50);
  --alert-bg-success: var(--green-100);
  --bg-color: white;
  --fg-color: white;
  --subtle-accent: var(--gray-50);
  --subtle-fg: var(--gray-100);
  --navbar-bg: white;
  --fg-hover-color: var(--gray-100);
  --card-bg: var(--fg-color);
  --disabled-text-color: var(--gray-600);
  --disabled-control-bg: var(--gray-50);
  --control-bg: var(--gray-100);
  --control-bg-on-gray: var(--gray-200);
  --awesomebar-focus-bg: var(--fg-color);
  --modal-bg: white;
  --toast-bg: var(--modal-bg);
  --popover-bg: white;
  --awesomplete-hover-bg: var(--control-bg);
  --btn-primary: var(--gray-900);
  --btn-default-bg: var(--gray-100);
  --btn-default-hover-bg: var(--gray-300);
  --sidebar-select-color: var(--gray-200);
  --scrollbar-thumb-color: var(--gray-400);
  --scrollbar-track-color: var(--gray-200);
  --shadow-inset: inset 0px -1px 0px var(--gray-300);
  --border-color: var(--gray-200);
  --dark-border-color: var(--gray-300);
  --table-border-color: var(--gray-200);
  --highlight-color: var(--gray-50);
  --yellow-highlight-color: var(--yellow-50);
  --btn-group-border-color: var(--gray-300);
  --placeholder-color: var(--gray-50);
  --highlight-shadow: 1px 1px 10px var(--blue-50), 0px 0px 4px var(--blue-600);
  --code-block-bg: var(--gray-900);
  --code-block-text: var(--gray-400);
  --primary-color: var(--gray-900);
  --btn-height: 28px;
  --input-height: 28px;
  --input-disabled-bg: var(--gray-200);
  --checkbox-right-margin: var(--margin-xs);
  --checkbox-size: 14px;
  --checkbox-color: var(--neutral-black);
  --checkbox-focus-shadow: 0 0 0 2px var(--gray-300);
  --checkbox-gradient: linear-gradient(180deg, var(--primary) -124.51%, var(--primary) 100%);
  --checkbox-disabled-gradient: linear-gradient(180deg, var(--disabled-control-bg) -124.51%, var(--disabled-control-bg) 100%);
  --switch-bg: var(--gray-300);
  --diff-added: var(--green-100);
  --diff-removed: var(--red-100);
  --right-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.25 7.5L4.75 4L1.25 0.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --left-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 9.5L4 6l3.5-3.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
}

:root,
[data-theme=light] {
  --xxl-width: ;
  --xl-width: 1200px;
  --lg-width: 992px;
  --md-width: 768px;
  --sm-width: 576px;
  --xs-width: 0;
  --appreciation-color: var(--green-600);
  --appreciation-bg: var(--green-100);
  --criticism-color: var(--red-600);
  --criticism-bg: var(--red-100);
  --error-bg: var(--red-50);
  --error-border: var(--red-400);
  --page-head-height: 60px;
  --page-bottom-margin: 60px;
  --checkbox-right-margin: var(--margin-xs);
  --checkbox-size: 14px;
  --checkbox-focus-shadow: var(--focus-default);
  --date-active-text: var(--gray-100);
  --date-active-bg: var(--gray-900);
  --date-range-bg: var(--subtle-fg);
  --timeline-item-icon-size: 30px;
  --timeline-item-left-margin: var(--margin-lg);
  --timeline-item-bottom-margin: var(--margin-sm);
  --timeline-content-max-width: 700px;
  --timeline-left-padding: calc(var(--padding-xl) + var(--timeline-item-icon-size) / 2);
  --timeline-badge-color: var(--gray-900);
  --timeline-badge-bg: var(--gray-100);
  --user-mention-bg-color: var(--fg-color);
  --group-mention-bg-color: var(--bg-purple);
  --skeleton-bg: var(--gray-100);
  --progress-bar-bg: var(--primary);
}

[data-theme=dark] {
  --neutral: var(--neutral-black);
  --invert-neutral: var(--neutral-white);
  --gray-700: #383838;
  --gray-800: #232323;
  --text-neutral: var(--gray-50);
  --text-dark: var(--gray-900);
  --text-muted: var(--gray-400);
  --text-light: var(--gray-300);
  --text-color: var(--gray-50);
  --heading-color: var(--gray-50);
  --icon-fill: transparent;
  --icon-stroke: var(--gray-300);
  --error-bg: var(--red-70);
  --error-border: var(--red-400);
  --bg-color: var(--gray-900);
  --fg-color: var(--gray-900);
  --subtle-accent: var(--gray-700);
  --subtle-fg: var(--gray-800);
  --navbar-bg: var(--gray-900);
  --fg-hover-color: var(--gray-800);
  --card-bg: var(--gray-900);
  --disabled-text-color: var(--gray-400);
  --disabled-control-bg: var(--gray-800);
  --control-bg: var(--gray-800);
  --control-bg-on-gray: var(--gray-800);
  --awesomebar-focus-bg: var(--control-bg);
  --awesomplete-hover-bg: var(--gray-800);
  --modal-bg: var(--gray-900);
  --toast-bg: var(--modal-bg);
  --popover-bg: var(--bg-color);
  --btn-default-bg: var(--gray-800);
  --btn-default-hover-bg: var(--gray-700);
  --btn-primary: var(--gray-300);
  --bg-blue: var(--blue-600);
  --bg-light-blue: var(--blue-600);
  --bg-dark-blue: var(--blue-900);
  --bg-green: var(--green-900);
  --bg-yellow: var(--yellow-700);
  --bg-orange: var(--orange-700);
  --bg-red: var(--red-600);
  --bg-gray: var(--gray-600);
  --bg-grey: var(--gray-600);
  --bg-darkgrey: var(--gray-600);
  --bg-dark-gray: var(--gray-500);
  --bg-light-gray: var(--gray-800);
  --bg-purple: var(--purple-700);
  --bg-pink: var(--pink-700);
  --bg-cyan: var(--cyan-800);
  --text-on-blue: var(--blue-50);
  --text-on-light-blue: var(--blue-50);
  --text-on-dark-blue: var(--blue-300);
  --text-on-green: var(--green-100);
  --text-on-yellow: var(--yellow-50);
  --text-on-orange: var(--orange-100);
  --text-on-red: var(--red-50);
  --text-on-gray: var(--gray-50);
  --text-on-grey: var(--gray-50);
  --text-on-darkgrey: var(--gray-200);
  --text-on-dark-gray: var(--gray-200);
  --text-on-light-gray: var(--gray-100);
  --text-on-purple: var(--purple-100);
  --text-on-pink: var(--pink-100);
  --text-on-cyan: var(--cyan-100);
  --alert-text-danger: var(--red-300);
  --alert-text-warning: var(--yellow-300);
  --alert-text-info: var(--blue-300);
  --alert-text-success: var(--green-300);
  --alert-bg-danger: var(--red-900);
  --alert-bg-warning: var(--yellow-900);
  --alert-bg-info: var(--blue-900);
  --alert-bg-success: var(--green-900);
  --sidebar-select-color: var(--gray-800);
  --scrollbar-thumb-color: var(--gray-600);
  --scrollbar-track-color: var(--gray-700);
  --timeline-badge-color: var(--gray-500);
  --timeline-badge-bg: var(--gray-900);
  --shadow-inset: var(--fg-color);
  --border-color: var(--gray-800);
  --dark-border-color: var(--gray-600);
  --table-border-color: var(--border-color);
  --highlight-color: var(--gray-700);
  --yellow-highlight-color: var(--yellow-700);
  --btn-group-border-color: var(--gray-700);
  --placeholder-color: var(--gray-700);
  --highlight-shadow: 1px 1px 10px var(--blue-900), 0px 0px 4px var(--blue-500);
  --shadow-base: 0px 4px 8px rgba(114, 176, 233, 0.06), 0px 0px 4px rgba(112, 172, 228, 0.12);
  --diff-added: var(--green-800);
  --diff-removed: var(--red-800);
  --input-disabled-bg: none;
  --checkbox-color: var(--neutral-white);
  --checkbox-gradient: linear-gradient(180deg, var(--neutral-white) -124.51%, var(--neutral-white) 100%);
  --checkbox-disabled-gradient: linear-gradient(180deg, var(--gray-600) -124.51%, var(--gray-600) 100%);
  --checkbox-focus-shadow: var(--focus-default);
  --switch-bg: var(--gray-500);
  --date-active-text: var(--gray-100);
  --date-active-bg: var(--gray-700);
  --date-range-bg: var(--subtle-fg);
  color-scheme: dark;
  --skeleton-bg: var(--gray-800);
  --right-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.25 7.5L4.75 4L1.25 0.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --left-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 9.5L4 6l3.5-3.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
}
[data-theme=dark] .page-title .sidebar-toggle-btn {
  --icon-stroke: var(--gray-300);
}
[data-theme=dark] input[type=checkbox]:checked {
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>"), var(--checkbox-gradient);
}
[data-theme=dark] input[type=checkbox].disabled-selected, [data-theme=dark] input[type=checkbox]:disabled:checked {
  background-image: url("data:image/svg+xml, <svg viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.00001L2.66667 5.80001L7 1.20001' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>"), var(--checkbox-disabled-gradient);
}
[data-theme=dark] input[type=checkbox].disabled-deselected {
  border: none;
  background-color: var(--gray-700);
}
[data-theme=dark] .grid-body .editable-row {
  --control-bg: var(--gray-900);
  --input-disabled-bg: var(--gray-800);
}
[data-theme=dark] .frappe-card .btn-default {
  background-color: var(--control-bg);
}
[data-theme=dark] .frappe-card .btn-default:hover {
  background-color: var(--fg-hover-color);
}
[data-theme=dark] .modal,
[data-theme=dark] .form-in-grid {
  --control-bg: var(--gray-800);
  --border-color: var(--gray-800);
}
[data-theme=dark] .print-format {
  --text-color: var(--gray-900);
  --border-color: var(--gray-100);
}
[data-theme=dark] .ql-editor {
  color: var(--text-on-gray);
}
[data-theme=dark] .ql-editor.read-mode span:not(.mention),
[data-theme=dark] .ql-editor.read-mode p,
[data-theme=dark] .ql-editor.read-mode u,
[data-theme=dark] .ql-editor.read-mode strong {
  background-color: inherit !important;
  color: inherit !important;
}
[data-theme=dark] .comment-input-wrapper .ql-editor.ql-blank::before {
  color: var(--text-color);
}
[data-theme=dark] .chart-container {
  --charts-label-color: var(--gray-300);
  --charts-axis-line-color: var(--subtle-fg);
  --charts-stroke-width: 5px;
  --charts-dataset-circle-stroke: #ffffff;
  --charts-dataset-circle-stroke-width: var(--charts-stroke-width);
  --charts-tooltip-title: var(--charts-label-color);
  --charts-tooltip-label: var(--charts-label-color);
  --charts-tooltip-value: white;
  --charts-tooltip-bg: var(--gray-900);
  --charts-legend-label: var(--charts-label-color);
}
[data-theme=dark] .heatmap-chart g > rect[fill="#ebedf0"] {
  fill: var(--gray-700);
}
[data-theme=dark] .rating {
  --star-fill: var(--gray-500);
}
[data-theme=dark] .rating .star-hover {
  --star-fill: var(--gray-400);
}
[data-theme=dark] ::-moz-selection {
  color: var(--text-color);
  background: var(--gray-500);
}
[data-theme=dark] ::selection {
  color: var(--text-color);
  background: var(--gray-500);
}
[data-theme=dark] .indicator {
  --indicator-dot-green: var(--bg-green);
}
[data-theme=dark] .indicator {
  --indicator-dot-cyan: var(--bg-cyan);
}
[data-theme=dark] .indicator {
  --indicator-dot-blue: var(--bg-blue);
}
[data-theme=dark] .indicator {
  --indicator-dot-orange: var(--bg-orange);
}
[data-theme=dark] .indicator {
  --indicator-dot-yellow: var(--bg-yellow);
}
[data-theme=dark] .indicator {
  --indicator-dot-gray: var(--bg-gray);
}
[data-theme=dark] .indicator {
  --indicator-dot-grey: var(--bg-grey);
}
[data-theme=dark] .indicator {
  --indicator-dot-red: var(--bg-red);
}
[data-theme=dark] .indicator {
  --indicator-dot-pink: var(--bg-pink);
}
[data-theme=dark] .indicator {
  --indicator-dot-darkgrey: var(--bg-darkgrey);
}
[data-theme=dark] .indicator {
  --indicator-dot-purple: var(--bg-purple);
}
[data-theme=dark] .indicator {
  --indicator-dot-light-blue: var(--bg-light-blue);
}

@media (min-width: 576px) {
  body {
    background-color: var(--bg-light-gray);
  }
}
body .web-footer {
  display: none;
}

.for-forgot,
.for-login-with-email-link,
.for-signup,
.for-email-login {
  display: none;
}

.for-login .page-card,
.for-forgot .page-card,
.for-login-with-email-link .page-card,
.for-signup .page-card,
.for-email-login .page-card {
  padding: 45px 10px;
  background-color: #fff;
  max-width: 400px;
  margin: 0 auto;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
}
.for-login .page-card form,
.for-forgot .page-card form,
.for-login-with-email-link .page-card form,
.for-signup .page-card form,
.for-email-login .page-card form {
  max-width: 320px;
  margin: 0 auto;
}
.for-login .page-card form .form-group,
.for-forgot .page-card form .form-group,
.for-login-with-email-link .page-card form .form-group,
.for-signup .page-card form .form-group,
.for-email-login .page-card form .form-group {
  margin-bottom: var(--margin-sm);
}
.for-login .page-card .page-card-actions,
.for-forgot .page-card .page-card-actions,
.for-login-with-email-link .page-card .page-card-actions,
.for-signup .page-card .page-card-actions,
.for-email-login .page-card .page-card-actions {
  margin-top: var(--margin-lg);
}
.for-login .page-card .page-card-body ::placeholder,
.for-login .page-card .page-card-body ::-ms-input-placeholder,
.for-forgot .page-card .page-card-body ::placeholder,
.for-forgot .page-card .page-card-body ::-ms-input-placeholder,
.for-login-with-email-link .page-card .page-card-body ::placeholder,
.for-login-with-email-link .page-card .page-card-body ::-ms-input-placeholder,
.for-signup .page-card .page-card-body ::placeholder,
.for-signup .page-card .page-card-body ::-ms-input-placeholder,
.for-email-login .page-card .page-card-body ::placeholder,
.for-email-login .page-card .page-card-body ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-light);
  opacity: 1; /* Firefox */
}
.for-login .page-card .page-card-body input[type=text],
.for-login .page-card .page-card-body input[type=email],
.for-login .page-card .page-card-body input[type=password],
.for-forgot .page-card .page-card-body input[type=text],
.for-forgot .page-card .page-card-body input[type=email],
.for-forgot .page-card .page-card-body input[type=password],
.for-login-with-email-link .page-card .page-card-body input[type=text],
.for-login-with-email-link .page-card .page-card-body input[type=email],
.for-login-with-email-link .page-card .page-card-body input[type=password],
.for-signup .page-card .page-card-body input[type=text],
.for-signup .page-card .page-card-body input[type=email],
.for-signup .page-card .page-card-body input[type=password],
.for-email-login .page-card .page-card-body input[type=text],
.for-email-login .page-card .page-card-body input[type=email],
.for-email-login .page-card .page-card-body input[type=password] {
  border: none;
  color: var(--text-color);
  font-size: var(--text-base);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  background-color: var(--control-bg);
  margin-bottom: 1rem;
}
.for-login .page-card .page-card-body.invalid input[type=text],
.for-login .page-card .page-card-body.invalid input[type=email],
.for-login .page-card .page-card-body.invalid input[type=password],
.for-forgot .page-card .page-card-body.invalid input[type=text],
.for-forgot .page-card .page-card-body.invalid input[type=email],
.for-forgot .page-card .page-card-body.invalid input[type=password],
.for-login-with-email-link .page-card .page-card-body.invalid input[type=text],
.for-login-with-email-link .page-card .page-card-body.invalid input[type=email],
.for-login-with-email-link .page-card .page-card-body.invalid input[type=password],
.for-signup .page-card .page-card-body.invalid input[type=text],
.for-signup .page-card .page-card-body.invalid input[type=email],
.for-signup .page-card .page-card-body.invalid input[type=password],
.for-email-login .page-card .page-card-body.invalid input[type=text],
.for-email-login .page-card .page-card-body.invalid input[type=email],
.for-email-login .page-card .page-card-body.invalid input[type=password] {
  border: 1px solid var(--red-500);
}
.for-login .page-card .page-card-body .form-label,
.for-forgot .page-card .page-card-body .form-label,
.for-login-with-email-link .page-card .page-card-body .form-label,
.for-signup .page-card .page-card-body .form-label,
.for-email-login .page-card .page-card-body .form-label {
  font-size: var(--text-base);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
}
.for-login .page-card .page-card-body .forgot-password-message,
.for-forgot .page-card .page-card-body .forgot-password-message,
.for-login-with-email-link .page-card .page-card-body .forgot-password-message,
.for-signup .page-card .page-card-body .forgot-password-message,
.for-email-login .page-card .page-card-body .forgot-password-message {
  text-align: right;
  line-height: 1;
}
.for-login .page-card .page-card-body .forgot-password-message > *,
.for-forgot .page-card .page-card-body .forgot-password-message > *,
.for-login-with-email-link .page-card .page-card-body .forgot-password-message > *,
.for-signup .page-card .page-card-body .forgot-password-message > *,
.for-email-login .page-card .page-card-body .forgot-password-message > * {
  color: var(--text-light);
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
}
.for-login .page-card .page-card-body .field-icon,
.for-forgot .page-card .page-card-body .field-icon,
.for-login-with-email-link .page-card .page-card-body .field-icon,
.for-signup .page-card .page-card-body .field-icon,
.for-email-login .page-card .page-card-body .field-icon {
  left: 9px;
  top: 5px;
  position: absolute;
  z-index: 2;
}
.for-login .page-card .page-card-body .email-field,
.for-login .page-card .page-card-body .password-field,
.for-forgot .page-card .page-card-body .email-field,
.for-forgot .page-card .page-card-body .password-field,
.for-login-with-email-link .page-card .page-card-body .email-field,
.for-login-with-email-link .page-card .page-card-body .password-field,
.for-signup .page-card .page-card-body .email-field,
.for-signup .page-card .page-card-body .password-field,
.for-email-login .page-card .page-card-body .email-field,
.for-email-login .page-card .page-card-body .password-field {
  position: relative;
}
.for-login .page-card .page-card-body .email-field input,
.for-login .page-card .page-card-body .password-field input,
.for-forgot .page-card .page-card-body .email-field input,
.for-forgot .page-card .page-card-body .password-field input,
.for-login-with-email-link .page-card .page-card-body .email-field input,
.for-login-with-email-link .page-card .page-card-body .password-field input,
.for-signup .page-card .page-card-body .email-field input,
.for-signup .page-card .page-card-body .password-field input,
.for-email-login .page-card .page-card-body .email-field input,
.for-email-login .page-card .page-card-body .password-field input {
  padding-left: 35px;
}
.for-login .page-card .page-card-body .email-field .toggle-password,
.for-login .page-card .page-card-body .password-field .toggle-password,
.for-forgot .page-card .page-card-body .email-field .toggle-password,
.for-forgot .page-card .page-card-body .password-field .toggle-password,
.for-login-with-email-link .page-card .page-card-body .email-field .toggle-password,
.for-login-with-email-link .page-card .page-card-body .password-field .toggle-password,
.for-signup .page-card .page-card-body .email-field .toggle-password,
.for-signup .page-card .page-card-body .password-field .toggle-password,
.for-email-login .page-card .page-card-body .email-field .toggle-password,
.for-email-login .page-card .page-card-body .password-field .toggle-password {
  right: 9px;
  top: 5px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  font-size: 12px;
}
.for-login .page-card .page-card-body .btn-login-option,
.for-forgot .page-card .page-card-body .btn-login-option,
.for-login-with-email-link .page-card .page-card-body .btn-login-option,
.for-signup .page-card .page-card-body .btn-login-option,
.for-email-login .page-card .page-card-body .btn-login-option {
  font-size: var(--text-base);
  font-weight: var(--weight-medium);
  letter-spacing: 0.015em;
  color: var(--text-gray-700);
  background: var(--gray-100);
  box-shadow: none;
  border: none;
  display: flex;
  place-content: center;
}
.for-login .page-card .page-card-body .btn-login-option.btn-salesforce i,
.for-forgot .page-card .page-card-body .btn-login-option.btn-salesforce i,
.for-login-with-email-link .page-card .page-card-body .btn-login-option.btn-salesforce i,
.for-signup .page-card .page-card-body .btn-login-option.btn-salesforce i,
.for-email-login .page-card .page-card-body .btn-login-option.btn-salesforce i {
  color: var(--blue-400);
}
.for-login .page-card .page-card-body .btn-login-option img,
.for-forgot .page-card .page-card-body .btn-login-option img,
.for-login-with-email-link .page-card .page-card-body .btn-login-option img,
.for-signup .page-card .page-card-body .btn-login-option img,
.for-email-login .page-card .page-card-body .btn-login-option img {
  margin-right: var(--padding-xs);
}
.for-login .page-card .page-card-body .btn-login-option:hover,
.for-forgot .page-card .page-card-body .btn-login-option:hover,
.for-login-with-email-link .page-card .page-card-body .btn-login-option:hover,
.for-signup .page-card .page-card-body .btn-login-option:hover,
.for-email-login .page-card .page-card-body .btn-login-option:hover {
  border: none;
  background: var(--gray-300);
}
.for-login .page-card .page-card-body .social-logins,
.for-forgot .page-card .page-card-body .social-logins,
.for-login-with-email-link .page-card .page-card-body .social-logins,
.for-signup .page-card .page-card-body .social-logins,
.for-email-login .page-card .page-card-body .social-logins {
  margin-top: var(--margin-md);
  font-size: var(--text-base);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
}
.for-login .page-card .page-card-body .social-logins .social-login-buttons,
.for-forgot .page-card .page-card-body .social-logins .social-login-buttons,
.for-login-with-email-link .page-card .page-card-body .social-logins .social-login-buttons,
.for-signup .page-card .page-card-body .social-logins .social-login-buttons,
.for-email-login .page-card .page-card-body .social-logins .social-login-buttons {
  margin-top: var(--margin-md);
}
.for-login .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper,
.for-forgot .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper,
.for-login-with-email-link .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper,
.for-signup .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper,
.for-email-login .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper {
  min-width: 50%;
  padding: 0 4px;
  margin-bottom: var(--margin-md);
}
@media (min-width: 768px) {
  .for-login .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper,
  .for-forgot .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper,
  .for-login-with-email-link .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper,
  .for-signup .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper,
  .for-email-login .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper {
    min-width: 33.33%;
  }
}
.for-login .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper:last-child,
.for-forgot .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper:last-child,
.for-login-with-email-link .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper:last-child,
.for-signup .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper:last-child,
.for-email-login .page-card .page-card-body .social-logins .social-login-buttons .login-button-wrapper:last-child {
  margin-bottom: 0;
}
.for-login .page-card .page-card-body .login-divider,
.for-forgot .page-card .page-card-body .login-divider,
.for-login-with-email-link .page-card .page-card-body .login-divider,
.for-signup .page-card .page-card-body .login-divider,
.for-email-login .page-card .page-card-body .login-divider {
  margin: var(--margin-md) 0;
}
.for-login .sign-up-message,
.for-forgot .sign-up-message,
.for-login-with-email-link .sign-up-message,
.for-signup .sign-up-message,
.for-email-login .sign-up-message {
  margin-top: 1rem;
  margin-bottom: 0;
  color: var(--text-light);
  font-size: var(--text-base);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
}
.for-login .sign-up-message a,
.for-forgot .sign-up-message a,
.for-login-with-email-link .sign-up-message a,
.for-signup .sign-up-message a,
.for-email-login .sign-up-message a {
  color: var(--primary);
}
.for-login .invalid-login,
.for-forgot .invalid-login,
.for-login-with-email-link .invalid-login,
.for-signup .invalid-login,
.for-email-login .invalid-login {
  -webkit-animation: wiggle 0.5s linear;
}

.page-card-head {
  padding: max(5vh, 30px) 0 14px 0px;
  margin: 0 auto;
  text-align: center;
  font-size: var(--text-xl);
  font-weight: 600;
}
.page-card-head img {
  max-height: 42px;
}
.page-card-head h4 {
  margin-top: 1rem;
  font-size: var(--text-xl);
  font-weight: var(--weight-semibold);
  letter-spacing: 0.01em;
  color: var(--text-color);
}